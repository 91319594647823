import { useContext, useEffect } from 'react';
import { useRouter } from 'next/router';

import { MainContext } from '../components/context/MainProvider';

/**
 * A custom hook that handles redirection logic for logged-in users.
 *
 * This hook evaluates the user's state and - when user becomes avaliable after login - redirects them to the appropriate page based on:
 * - Concierge cohort membership
 * - Next path query parameter
 * - User role (mentor)
 * - Partnership status (Vitality employee or user)
 * - Profile completion status
 * - Payment details status
 * - KYC (Know Your Customer) verification status
 *
 * The hook uses the user information from the MainContext and the nextPath query parameter
 * from the router to determine the appropriate redirection path.
 *
 * @returns {void}
 */
export const useRedirectOnLogin = (): void => {
  const {
    query: { nextPath },
    replace,
    push,
  } = useRouter();

  const context = useContext(MainContext);
  const { user } = context;

  useEffect(() => {
    // If a user is logged in, redirect them
    if (!user) {
      return;
    }
    if (
      // block concierge cohorts from accessing the web app
      user.conciergeCohortNumber &&
      [14, 16, 17, 18, 19].includes(user.conciergeCohortNumber)
    ) {
      replace('/');
    } else if (nextPath) {
      // Check if a separate subdomain is being used for the redirect
      // If so, redirect to the subdomain
      if (nextPath.toString().startsWith('http')) {
        window.location.href = nextPath.toString();
      } else {
        // If nextPath starts with a '/', remove it to avoid double slashes
        push(
          `/${nextPath.toString().startsWith('/') ? nextPath.toString().substring(1) : nextPath}`,
        );
      }
    } else if (user.mentor && process.env.BASECAMP_URL) {
      // We need to use window.location.href here as push() does not work for a redirect to a different origin
      window.location.href = process.env.BASECAMP_URL;
    } else if (user.isVitalityEmployee && !user.hasCompletedProfile) {
      replace('/partnerships/employee-vitality/address');
    } else if (user.isVitalityUser && !user.hasPaymentDetails) {
      replace('/partnerships/sn-vitality/programme-details');
    } else if (user.isVitalityUser && !user.hasCompletedProfile) {
      replace('/partnerships/sn-vitality/address');
      // Vitality MSP users
    } else if (user.provider === 'Vitality' && !user.hasPaymentDetails) {
      replace('/partnerships/sn-vitality/qualifies-for-medication');
      // Redirect users who have an account but have not paid to the pre-checkout page
      // We do not send them directly to the checkout as they need to submit their account details in the create account modal
      // before they can proceed to the checkout to ensure the quiz answers are saved in the DB
    } else if (!user.hasPaymentDetails) {
      replace('/get-plan/calculating-plan');
      // if a user has passed KYC and has not completed their profile, we need to ensure they have
      // completed their GP details. We use KYC as a proxy for someone being a GLP-1 user, as this field is the
      // clearest indicator in the user data we have returned from the MainContext that they are GLP-1.
    } else if (!user.hasCompletedProfile && user.kyc?.result === 'PASS') {
      replace('/glp1/gp-details');
    } else if (!user.hasCompletedProfile) {
      replace('/complete-profile');
    } else if (process.env.WEB_APP_URL) {
      // We need to use window.location.href here as push() does not work for a redirect to a different origin
      window.location.href = process.env.WEB_APP_URL;
    } else {
      replace('/');
    }
  }, [user, nextPath, push, replace]);
};
